import React from 'react';
import { saveAs } from 'file-saver';
import ReactPDF, { pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { simpleDateBR, simpleDateBRWithTrace } from '../../../../../../utils/date';
import { formatReal, formatRealWithHash } from '../../../../../../utils/format';

const handleFinanceReportPDF = async ({ data, project }) => {
    const date = simpleDateBR(new Date()?.toDateString());
    const dateTitle = simpleDateBRWithTrace(new Date()?.toDateString());

    const styles = StyleSheet.create({
        page: {
            width: '27.9cm',
            height: '21cm',
            backgroundColor: "#fff",
            color: "#333",
            padding: 10
        },
        table: {
            display: "flex",
            flexDirection: "column",
            width: "auto",
            marginTop: 10,
            borderWidth: 1,
            borderColor: '#fff',
        },
        tableRow: {
            flexDirection: "row",
            borderBottomWidth: 0,
            borderColor: '#fff',
            backgroundColor: '#fff',
        },
        tableRowHeader: {
            backgroundColor: '#ddd',
            borderTopWidth: 0,
        },
        tableColHeader: {
            width: "100px",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#bfbfbf',
            textAlign: 'center',
            padding: 3,
            fontSize: '7px'
        },
        tableColHeaderSmall: {
            width: "50px",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#bfbfbf',
            textAlign: 'center',
            padding: 3,
            fontSize: '7px'
        },
        tableCol: {
            width: "100px",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#bfbfbf',
            textAlign: 'center',
            padding: 3,
            fontSize: '7px'
        },
        tableColSmall: {
            width: "50px",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#bfbfbf',
            textAlign: 'center',
            padding: 3,
            fontSize: '7px'
        },
        sectionHeader: {
            fontSize: '18px',
            marginTop: '0px'
        }
    });

    const items: any = [{
        saque_index: 1,
        rows: [],
        total_tax: 0,
        fees: 0,
        withdraw_tax: 0,
        antecipation_gap: 0,
        brute: 0,
        brute_without_fees: 0,
        liquid: 0,
        administration_tax: 0,
        saldo: 0,
        sacado: 0,
        date_from: project?.createdAt,
        date_to: new Date()
    }];
    let index = 0;

    data?.rows?.map(content => {
        if (content?.operation_type === 'Saque') {

            items[index].withdraw_tax = content.operation_fee;
            items[index].saldo = content.saldo;
            items[index].sacado = content.sacado;
            items[index].rows?.push(content);
            items[index].date_to = content.date;
            items.push({
                saque_index: index + 2,
                rows: [],
                total_tax: 0,
                fees: 0,
                antecipation_gap: 0,
                brute: 0,
                brute_without_fees: 0,
                liquid: 0,
                withdraw_tax: 0,
                administration_tax: 0,
                saldo: 0,
                sacado: 0,
                date_from: content?.date,
                date_to: new Date()
            });

            index += 1;
        } else {
            items[index].brute += content.total;
            items[index].total_tax += content.total_tax;
            items[index].fees += content.fees;
            items[index].brute_without_fees += (content.total - (content.fees || 0));
            items[index].antecipation_gap += content.antecipation_gap;
            items[index].administration_tax += content.administration_tax;
            items[index].saldo = content.saldo;
            items[index].liquid += content.net_total;
            items[index].rows?.push(content);
        }
    });

    const pdfContent = items.map((item) => {
        const tableRows = item.rows.map(row => (row.operation_type === 'Saque' ? <View style={styles.tableRow} key={row.id}>
            <Text style={styles.tableCol}>{simpleDateBR(row.date)}</Text>
            <Text style={styles.tableCol}>{row.operation_type}</Text>
            <Text style={styles.tableCol}></Text>
            <Text style={styles.tableCol}></Text>
            <Text style={styles.tableCol}></Text>
            <Text style={styles.tableColSmall}></Text>
            <Text style={styles.tableCol}>{formatRealWithHash(0)}</Text>
            <Text style={styles.tableCol}>{formatRealWithHash(0)}</Text>
            <Text style={styles.tableCol}>{formatRealWithHash(0)}</Text>
            <Text style={styles.tableCol}>{formatRealWithHash(row.operation_fee)}</Text>
            <Text style={styles.tableCol}>{formatRealWithHash(0)}</Text>
            <Text style={styles.tableCol}>{formatRealWithHash(row.total_expense)}</Text>
            <Text style={styles.tableCol}>{formatRealWithHash(row.saldo)}</Text>
            <Text style={styles.tableCol}></Text>
            <Text style={styles.tableCol}></Text>
        </View> :
            <View style={styles.tableRow} key={row.id}>
                <Text style={styles.tableCol}>{simpleDateBR(row.date)}</Text>
                <Text style={styles.tableCol}>{row.operation_type}</Text>
                <Text style={styles.tableCol}>{row.payment_method_id}</Text>
                <Text style={styles.tableCol}>{row.payment_status_id}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.total)}</Text>
                <Text style={styles.tableColSmall}>{row.installment}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.fees || 0)}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.total_tax)}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.antecipation_gap)}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.administration_tax)}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.total_recipe)}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.total_expense)}</Text>
                <Text style={styles.tableCol}>{formatRealWithHash(row.saldo)}</Text>
                <Text style={styles.tableCol}>{row.product}</Text>
                <Text style={styles.tableCol}>{row.client}</Text>
            </View>
        ));

        return (
            <Page wrap orientation="landscape" style={styles.page} key={item.saque_index}>
                <Text style={styles.sectionHeader}>{project?.reference_number} - {project?.title}</Text>

                <Text style={{ ...styles.sectionHeader, fontSize: '12px' }}>Saque: {item.saque_index} - de: {simpleDateBR(item?.date_from)} até {simpleDateBR(item?.date_to)}</Text>
                <Text style={{ ...styles.sectionHeader, fontSize: '12px' }}>Bruto: {formatRealWithHash(item.brute)} - Líquido: {formatRealWithHash(item.liquid)}</Text>


                <View style={[styles.table, styles.tableRowHeader]}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableColHeader}>Data</Text>
                        <Text style={styles.tableColHeader}>Tipo de Operação</Text>
                        <Text style={styles.tableColHeader}>Método de Pagamento</Text>
                        <Text style={styles.tableColHeader}>Status</Text>
                        <Text style={styles.tableColHeader}>Bruto</Text>
                        <Text style={styles.tableColHeaderSmall}>Parcelas</Text>
                        <Text style={styles.tableColHeader}>Juros de Parcelamento</Text>
                        <Text style={styles.tableColHeader}>Custo da Venda</Text>
                        <Text style={styles.tableColHeader}>Diferença de Antecipação</Text>
                        <Text style={styles.tableColHeader}>Taxa</Text>
                        <Text style={styles.tableColHeader}>Entrada Líquida</Text>
                        <Text style={styles.tableColHeader}>Saída Líquida</Text>
                        <Text style={styles.tableColHeader}>Saldo</Text>
                        <Text style={styles.tableColHeader}>Produto</Text>
                        <Text style={styles.tableColHeader}>Cliente</Text>
                    </View>
                    {tableRows}
                </View>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableRowHeader]}>
                        <Text style={styles.tableColHeader}>Resumo</Text>
                        <Text style={styles.tableColHeader}></Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>Bruto</Text>
                        <Text style={styles.tableCol}>Juros de Parcelamento</Text>
                        <Text style={styles.tableCol}>Bruto sem Juros</Text>
                        <Text style={styles.tableCol}>Taxas de Venda</Text>
                        <Text style={styles.tableCol}>Diferença de Antecipação</Text>
                        <Text style={styles.tableCol}>Taxas Administrativas</Text>

                        <Text style={styles.tableCol}>Líquido</Text>
                        <Text style={styles.tableCol}>Taxa de Saque</Text>
                        <Text style={styles.tableCol}>Valor do Saque</Text>
                        <Text style={styles.tableCol}>Saldo</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.brute)}</Text>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.fees)}</Text>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.brute_without_fees)}</Text>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.total_tax)}</Text>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.antecipation_gap)}</Text>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.administration_tax)}</Text>

                        <Text style={styles.tableCol}>{formatRealWithHash(item.liquid)}</Text>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.withdraw_tax)}</Text>
                        <Text style={styles.tableCol}>R$ {formatReal(item.sacado)}</Text>
                        <Text style={styles.tableCol}>{formatRealWithHash(item.saldo)}</Text>
                    </View>

                </View>
            </Page>
        );
    });

    const DocumentElements = () => <Document>{pdfContent}</Document>;

    try {
        const blob = await pdf(<DocumentElements />).toBlob();
        saveAs(blob, `${date}-${project?.reference_number}-${project?.title}.pdf`);
    } catch (err) {
        console.log(err);
    }
};

export default handleFinanceReportPDF;