import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Politica: React.FC = () => {
  return (
    <>
      <ContainerWhite id="termos">
        <div className="p50 p600" style={{ textAlign: 'justify' }}>
          <h2>  POLÍTICA DE REEMBOLSO DE INGRESSOS</h2>
          <br />

          <p>
            <br /><br />A aquisição de ingressos ou serviços através do website Everaldo Rodrigues é considerada definitiva, não sendo passível de reembolso, exceto nos casos previstos por lei, especialmente no exercício do direito de arrependimento, ou mediante expressa decisão dos organizadores do evento. Portanto, é essencial garantir que você tenha examinado cuidadosamente e concordado com todas as informações e condições estabelecidas pelos organizadores do evento.
            <br /><br />No contexto de arrependimento, o Código de Defesa do Consumidor (Artigo 49) estabelece um prazo de até 7 (sete) dias para que o consumidor possa desistir da compra, contanto que essa decisão seja comunicada até 48 (quarenta e oito) horas antes do evento. O reembolso será processado através de um sistema de pagamento online, utilizando a mesma forma de pagamento inicial, com desconto da taxa de conveniência (se aplicável), dentro de um período de até 45 (quarenta e cinco) dias após o cancelamento. No caso de transações com cartões de crédito, o valor será restituído como crédito nas faturas subsequentes.
            <br /><br />Ultrapassados os mencionados prazos, não será mais possível a desistência da compra.
            <br /><br />É válido destacar que o cancelamento de uma compra só é possível de forma integral, não sendo permitido o cancelamento parcial. Assim, não há a opção de cancelar um ou mais itens (ingressos) dentro de uma compra, a menos que esses itens representem a totalidade da transação.
          </p>

        </div>
      </ContainerWhite>
    </>
  );
};
export default Politica;
