import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponentUnique, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaEdit, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import { Form } from '@unform/web';
import JsonList from '../../../../core/components/Forms/JsonListIncluder';
import UploadImage from '../../../../core/components/Forms/UploadImage';
import Button from '../../../../core/components/Forms/Button';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;

}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addLoading, removeLoading } = useLoading();

  const key = uuid();

  const sendCortesiaResponse = async (status, answer) => {
    addLoading({ title: 'Carregando', key: 'loading' })
    try {

      const response = await api.post(`/sale-cortesia-answer/${lineData.sale_hash}`, { status, answer });
      addToast({ title: response?.data?.message || 'Resposta enviada com sucesso', type: 'success' });
      removeModal('cortesiaAnswer');

      setTimeout(() => {
        reloadSearchAll();
        removeLoading('loading');
      }, (2000));

    }
    catch (err) {
      addToast({ title: err?.response?.data?.error || err?.response?.data?.message || 'Erro ao enviar resposta ao inscrito', type: 'success' });
      removeLoading('loading');
    }

  }

  const handleUpdate = async (status): Promise<void> => {

    const message = {
      yes: 'Você deseja confirmar este participante?',
      no: 'Você deseja negar este participante?'
    }

    const content = (
      <Form onSubmit={(data) => sendCortesiaResponse(status, data?.answer)}>
        <p style={{ color: '#333', width: '100%', textAlign: 'center', fontWeight: 'bold', margin: '15px 0px' }}>
          {message?.[status]}
        </p>

        <TextareaInput placeholder='Escrever resposta ao inscrito (não obrigatório)' name='answer' />
        <aside style={{ display: 'flex', justifyContent: 'center', width: '100%' }}> <Button>Enviar</Button></aside>
      </Form>
    );
    if (content) {
      const keyModal = addModal({ title: '', content, key: 'cortesiaAnswer', theme: "whiteModalMini" });
    }
  };


  const moduleUpdate = (lineData): JSX.Element => (lineData?.payment_method_id === 'cortesia' && lineData?.payment_status_id === 'waiting' ? <>
    <button className="lineIcon" title="Editar" type="button" onClick={() => handleUpdate('yes')} style={{ background: "green" }}>
      <FaThumbsUp style={{ color: '#fff' }} />
    </button>

    <button className="lineIcon" title="Editar" type="button" onClick={() => handleUpdate('no')} style={{ background: "red" }}>
      <FaThumbsDown style={{ color: '#fff' }} />
    </button>
  </> : <></>
  );

  return moduleUpdate(lineData);
}
