import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, hash, recaptchaKey, urlWebsite } from '../../../config';

import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';

import { useConfiguration } from '../../../hooks/Configuration';
import { loadFromJson } from '../../../utils/loadFromJson';

import { useAuth } from '../../../hooks/Auth';

import { useLoading } from '../../../hooks/LoadingHook';
import DefaultSignUpFormV4 from '../../DefaultSignUpFormV4';

interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
  hashcode: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {

  const [checked, setChecked] = useState(false);
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otherCountry, setOtherCountry] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { projectHash, eventHash, productId, hashcode } = useParams<IParams>();
  const { configuration } = useConfiguration();
  const [emailRecord, setEmailRecord] = useState('');
  const { signIn } = useAuth();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const [global, setGlobal] = useState<Record<string, any>>({});
  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');

  const [category, setCategory] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('');

  const { addLoading, removeLoading } = useLoading()


  const [errorRequestMessage, setErrorRequestMessage] = useState('');

  useEffect(() => {
    if (configuration?.have_password === 'no') {
      setWithPassword(false);
    }

  }, [configuration])

  const loadConfig = async () => {
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }

    setGlobal(data);
  }

  useEffect(() => {
    loadConfig()
  }, [])

  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };

  const [courtesyFields, setCourtesyFields] = useState(false);

  const handleFinish = async ({ email, codigo, product, data }) => {

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';
    try {
      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];

        const shape = Yup.object().shape({});

        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


        const schema = shape;
        await schema.validate(data, { abortEarly: false });


      }
      await applyCourtesy(email, codigo, {
        fields: { ...fieldsValues },
        fields_text: fieldsText
      });


    }
    catch (err) {
      addToast({ title: 'Erro ao utilizar a cortesia', type: 'error' })
    }






  }



  const handleNewLogin = async (data: IRecoverFormData) => {
    addLoading({ title: 'Preparando seu cadastro', key: 'subscription' })

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';

    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }


    let eventFields = configuration?.current_event_id_response?.sign_up_fields || [];

    if (eventFields?.length < 1) {


      if (global?.sign_up_fields?.length > 0) {
        eventFields = [...global?.sign_up_fields];
      }
    }

    try {
      formRef.current?.setErrors({});


      const shape = countryISO === 'BR' ? Yup.object().shape({

        name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
        email: Yup.string()
          .required(`${translate('Email')} ${translate('obrigatório')}`)
          .email(`${translate('Email')} ${translate('obrigatório')}`),



        phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),

        password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

        document_number: Yup.string().required(`${translate('CPF')} ${translate('obrigatório')}`),



      }) : Yup.object().shape({

        name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
        email: Yup.string()
          .required(`${translate('Email')} ${translate('obrigatório')}`)
          .email(`${translate('Email')} ${translate('obrigatório')}`),



        phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),

        password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

        document_number: Yup.string().required('Informe o número do seu documento'),



      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');
      data.document_number.toString().replace(/[^0-9]/g, '');






      eventFields?.map(field => {

        if (field?.mandatory === 'yes') {
          shape[field?.fields] = Yup.string().required(field?.title);
        }

      })


      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = shape;
      if (data.phone) {
        data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');
      }

      /*    if (countryISO === 'BR') {
           const validCPF = testCPF(data?.document_number);
   
           if (!validCPF) {
             addToast({ type: 'error', title: 'Preencha um CPF válido' })
             setLoading(false);
             return;
           }
         }
   
         */









      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        captcha: captchaValue,
        term: data.term,
        fields: { ...fieldsValues },
        fields_text: fieldsText
      };

      if (configuration?.current_event_id_response?.product_id) {
        // content.product = configuration?.current_event_id_response?.product_id;
      }


      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);

            removeLoading('subscription');
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          removeLoading('subscription');
          return false;
        });

      if (!newUser) {
        removeLoading('subscription');
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }
      const session = await signIn({ email: configuration?.sign_in_type === 'cpf' ? data?.document_number : data?.email, password: data?.password, project_id: projectHash })
      removeLoading('subscription');
      await applyCourtesy(data.email, hashcode, {
        fields: { ...fieldsValues },
        fields_text: fieldsText
      });



      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        removeLoading('subscription');
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      removeLoading('subscription');
    }
  }


  const checkCourtesy = async (cortesyHash) => {

    addLoading({ title: 'Validando cortesia', key: 'cortesia' })

    try {
      const confirm = await api.get(`/v3/validate-cortesy-hash/${cortesyHash}/${projectHash}`);

      setChecked(true)
      setProduct(confirm?.data?.product)

      removeLoading('cortesia')
    }
    catch (err) {
      setErrorRequestMessage(err?.response?.data?.message || err?.response?.data?.error || 'Erro ao validar cortesia');
      removeLoading('cortesia')
    }


  }

  useEffect(() => {

    if (hashcode) {
      checkCourtesy(hashcode)
    }

  }, [hashcode])


  const applyCourtesy = async (email, hashValue, data) => {
    addLoading({ title: 'Aplicando Cortesia', key: 'aplicar-cortesia' })

    try {
      const checkCourtesy = await api.post(`/v3/confirm-courtesy/${hashValue}`, { email: email, ...data });

      if (checkCourtesy?.data?.confirmed === 'yes') {
        setSuccess(true);
      }
      else if (checkCourtesy?.data?.used === 'yes') {
        setErrorRequestMessage('Esta cortesia já foi utilizada');
      }
      else {
        setErrorRequestMessage('Erro ao aplicar cortesia');
      }
      removeLoading('aplicar-cortesia')

    }
    catch (err) {

      setErrorRequestMessage('Erro ao aplicar cortesia');
      removeLoading('aplicar-cortesia')
    }


  }

  const handleConfirmation = async (data) => {
    addLoading({ title: 'Verificando...', key: 'check' })
    data.email = data?.email?.trim()?.toLowerCase() || '';

    try {
      const checkUser = await api.post(`/v3/check-user/${hashcode}`, { email: data.email, hash: hashcode });

      if (checkUser.data.status === 'no') {
        setEmailRecord(data.email);
        setReady(true);
      }
      else if (checkUser.data.status === 'yes' && checkUser.data.used === 'yes') {
        setErrorRequestMessage('Você já utilizou esta cortesia para este cadastro.');
      }
      else if (checkUser.data.status === 'yes' && checkUser.data.used === 'no') {
        setEmailRecord(data.email);
        setCourtesyFields(true);



      }
      removeLoading('check')


    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao checar usuário, por favor, tente novamente' });
      removeLoading('check')
      return
    }


  }

  if (errorRequestMessage) {
    return <>
      <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center', fontSize: '18px', marginTop: '20px' }}>Verificamos um problema</h1>

      <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{errorRequestMessage}</p>

      <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
        Voltar
      </button></Link>
    </>
  }

  if (success) {
    return <ResponseMessage
      active
      type="clean"
      title=""
      description={
        <p style={{ color: 'rgb(0, 14, 64)' }}>
          <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Cortesia aplicada com sucesso </h2>
          <br />
          <p>Acesse a área do participante para visualizar seu ingresso</p>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link to={user ? `/app/${projectHash}/dashboard/` : `/app/${projectHash}/login`} >
              <button className="defaultButton" type="button">
                Acessar
              </button>
            </Link>
          </div>
          <br />
          {/* Aproveite e indique para seus amigos!
        <Whatsapp
          text={`Olá! Acabei de me inscrever no ${configuration?.current_event_id_response?.title}. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: ${urlWebsite}`}
          number=""
          message="Compartilhe!"
    /> */}
        </p>
      }
    />
  }


  if (!checked) {
    return <></>
  }

  if (courtesyFields) {

    return (
      <Form style={{ width: '100%', maxWidth: '350px' }} ref={formRefConfirmation} onSubmit={(data) => handleFinish({ email: emailRecord, codigo: hashcode, product, data })} initialData={{ country: 'BR', email: currentEmail }}>
        {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>Dados específicos do evento</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}
        <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <button className="defaultButton" type="submit">
          Finalizar
        </button></aside>
      </Form>)
  }

  if (!ready) {

    return (
      <Form style={{ width: '100%', maxWidth: '350px' }} ref={formRefConfirmation} onSubmit={handleConfirmation} initialData={{ country: 'BR', email: currentEmail }}>
        <h1 style={{ color: 'rgb(0,15,64)', fontSize: '18px', marginBottom: '10px' }}>Aplicar cortesia</h1>
        <p style={{ color: 'rgb(0,15,64)', fontSize: '14px', marginBottom: '20px' }}>Para iniciar, informe seu {configuration?.sign_in_type === 'cpf' ? 'CPF' : 'e-mail'}</p>
        {configuration?.sign_in_type === 'cpf' ? otherCountry ? <Input name="email" placeholder="Documento de identificação" /> : <MaskedInput mask={'999.999.999-99'} name="email" placeholder="CPF" /> : <Input name="email" placeholder="E-mail" />}

        {configuration?.sign_in_type === 'cpf' ? !otherCountry ? <p onClick={() => setOtherCountry(true)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>Sou de outra nacionalidade</p> : <p onClick={() => setOtherCountry(false)} style={{ color: '#333', margin: '10px auto', cursor: 'pointer' }}>Sou brasileiro</p> : <></>}
        <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <button className="defaultButton" type="submit">
          Continuar
        </button></aside>
      </Form>)
  }



  return (
    <>

      <aside>


        <>

          <Form ref={formRef} onSubmit={handleNewLogin} initialData={configuration?.sign_in_type === 'cpf' ? { country: 'BR', document_number: emailRecord } : { country: 'BR', email: emailRecord }}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Novo cadastro</h1>
            <DefaultSignUpFormV4 changeCountry={(value) => setCountryISO(value)} countryISO={countryISO} />


            {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>Dados específicos do evento</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}


            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
              <CheckboxInput
                style={{ display: 'flex' }}
                name="term"
                unique={true}
                preText={(
                  <>
                    {' '}
                    Estou ciente de que estou fornecendo à {authTitle} e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
                    {' '}

                  </>
                )}
                options={[
                  {
                    id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                    label: 'Sim, eu autorizo o compartilhamento dos meus dados',
                    value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  },
                  {
                    id: `Não autorizo o compartilhamento dos meus dados`,
                    label: 'Não autorizo o compartilhamento dos meus dados',
                    value: `Não autorizo o compartilhamento dos meus dados`,
                  },
                ]}
              />

              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {'Ao me cadastrar, afirmo que li a'}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
                    {' '}
                  </Link>
                  {' '}
                </strong>
                {'desta plataforma.'}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Finalizar inscrição


              </button>
            </aside>
          </Form>
        </>

      </aside>
    </>
  );
};

export default FormContainer;
