import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';




const PoliticaCancelamento: React.FC = () => {



  return (
    <>
      <ContainerWhite id="cancelamento">
        <div className="p50 p600 ">
          <h2>POLÍTICA DE CANCELAMENTO E REEMBOLSO</h2>
          <div className="separator" />
          <p>

            A inscrição somente será efetivada após a confirmação do pagamento. <br /><br />
            Solicitações de cancelamento serão aceitas somente quando formalizadas por e-mai: eventos@fundmed.org.br, com os dados de inscrição, em até 30 dias antes do início do evento. O reembolso será realizado em até 30 dias após o encerramento do evento, com retenção de 30% do valor pago. Não haverá reembolso para solicitações de cancelamento após o prazo de 30 dias antes do início do evento. Não haverá reembolso para o não comparecimento ao evento. <br /><br />Não haverá reembolso fora das normas estabelecidas.<br /><br /> Despesas de taxas bancárias correm por conta do solicitante.
          </p>
        </div>
      </ContainerWhite>
    </>
  );
};
export default PoliticaCancelamento;
