import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { FinanceReportByProjectModule } from './Modules/CheckFinance';
import downloadit from '../../../../utils/download';
import { useToast } from '../../../../hooks/Toast';
import template from './template/BlockRender';
import { useAuth } from '../../../../hooks/Auth';
import { CreateNewProjectModule } from './Modules/CreateNewProject';
import { DuplicateNewProjectModule } from './Modules/duplicateProject';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const EventsAdmin: React.FC = () => {
  const endpoint = '/projects';
  const title = 'Projetos';

  const { projectHash, eventHash } = useConfiguration();
  const { user } = useAuth();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    reference_number: { ref: 'reference_number', column: 'reference_number', label: 'Ref.' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    url: { ref: 'url', column: 'url', label: 'URL' },
    title: { ref: 'title', column: 'title', label: 'Nome do evento' },
    color: { ref: 'color', column: 'color', label: 'Cor do Projeto' },
    description: { ref: 'description', column: 'description', label: 'Nome do evento' },
    image: { ref: 'image', column: 'image', label: 'Capa de Seleção', show: false },
    data_image: { ref: 'data_image', column: 'data_image', label: 'Imagem da Dasa', show: false },
    logo: { ref: 'logo', column: 'logo', label: 'Logo', show: false },
    background: { ref: 'background', column: 'background', label: 'Fundo', show: false },
    background_color: { ref: 'background_color', column: 'background_color', label: 'Cor de Fundo', show: false },
    new_finance: { ref: 'new_finance', column: 'new_finance', label: 'Novo modelo de finanças', show: false },
    next_date: { ref: 'next_date', column: 'next_date', label: 'Próxima Data', show: false },
    upper_text: { ref: 'upper_text', column: 'upper_text', label: 'Texto superior da Capa', show: false },
    header_image: { ref: 'header_image', column: 'header_image', label: 'Imagem de capa', show: false },
    lower_text: { ref: 'lower_text', column: 'lower_text', label: 'Texto inferior da Capa', show: false },
    header_video: { ref: 'header_video', column: 'header_video', label: 'Vídeo', show: false },
    fixed_image_header: { ref: 'fixed_image_header', column: 'fixed_image_header', label: 'Imagem de fundo Fixa', show: false },
    current_event_id: {
      ref: 'current_event_id',
      column: 'current_event_id_response',
      includeColumn: 'title',
      label: 'Evento atual',
      filter: true,
    },

    status_id: {
      ref: 'status_id',
      column: 'status_id_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
      show: true,
    },
  };

  const formSchema = {
    reference_number: { model: 'input', type: 'text', name: 'reference_number', label: 'Número de referência' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do evento' },
    color: { model: 'input', type: 'text', name: 'color', label: 'Cor do projeto' },
    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'descritivo' },
    program_title: { model: 'input', type: 'text', name: 'program_title', label: 'Título da Programação' },
    program_subtitle: { model: 'input', type: 'text', name: 'program_subtitle', label: 'Subtítulo da Programação' },
    upper_text: { model: 'input', type: 'text', name: 'upper_text', label: 'Texto superior da Capa' },



    lower_text: { model: 'input', type: 'text', name: 'lower_text', label: 'Texto inferior da Capa' },
    header_video: { model: 'input', type: 'text', name: 'header_video', label: 'Link do Vídeo da Capa' },

    next_date: { model: 'datePickerHour', type: 'text', name: 'next_date', label: 'Próxima data' },

    background_color: { model: 'input', type: 'text', name: 'background_color', label: 'Cor de Fundo' },
    current_event_id: {
      alias: 'current_event_id',
      model: 'selectApi',
      name: 'current_event_id',
      label: 'Evento atual',
      endpoint,
    },
    status_id: {
      alias: 'status_id',
      model: 'selectApi',
      name: 'status_id',
      label: 'status',
      endpoint,
    },

    theme_data: {
      model: 'jsonList',
      type: 'text',
      name: 'theme_data',
      label: 'Temática',
      list: [
        { label: 'Fundo Primário', name: 'primary_background' },
        { label: 'Cor Primária', name: 'primary_color' },
        { label: 'Fundo secundária', name: 'secondary_background' },
        { label: 'Cor secundária', name: 'secondary_color' },
        {
          label: 'Página - Modelo', name: 'page-theme', type: 'select', options: [

            { label: 'Padrão', value: 'default' },
            { label: 'Single Page', value: 'single_page' },
            { label: 'Sidebar', value: 'sidebar' },

          ]
        },
        {
          label: 'Programação Modelo', name: 'program-theme', type: 'select', options: [

            { label: 'Padrão', value: 'default' },
            { label: 'Clean', value: 'clean' },

          ]
        },
        {
          label: 'Mostrar Cabeçalho da Programação?', name: 'program_header', type: 'select', default: 'yes', options: [

            { label: 'Sim', value: 'yes' },
            { label: 'Não', value: 'no' },

          ]
        },
        { label: 'Cor secundária', name: 'secondary_color' },
        { label: 'Cor secundária', name: 'secondary_color' },

      ],
    },

    active_pages: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'active_pages',
      label: 'Páginas / Menu',
      list: {
        hash: { label: 'Hash', name: 'hash' },
        title: { label: 'Título', name: 'title' },
        url: { label: 'Link', name: 'url' },
        menu: { label: 'Menu?', name: 'menu', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        button: { label: 'Botão', name: 'button', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        active: { label: 'Ativo?', name: 'active', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        signed: { label: 'Logado?', name: 'signed', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        type: { label: 'Tipo', name: 'type', type: 'select', options: [{ text: 'Página principal', value: 'page' }, { text: 'Aba separada', value: 'single_page' }, { text: 'Modal', value: 'modal' }, { text: 'Link externo', value: 'external' }, { text: 'Menu', value: 'menu' }] },
      },
      defaultValue:
        [
          { hash: 'home', url: '', title: 'Home', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'product', url: 'inscricao', title: 'Venda', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'signup', url: 'inscricao', title: 'Inscrição', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'program', url: 'programacao', title: 'Programação', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'speakers', url: 'palestrantes', title: 'Palestrantes', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'poster', url: 'poster', title: 'Pôster', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'certificate', url: 'certificados', title: 'Certificados', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
        ]
    },

    finance_status: {
      model: 'selectSimple',
      name: 'finance_status',
      label: 'Envio de relatório financeiro ativo?',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_discount: {
      model: 'selectSimple',
      name: 'allow_discount',
      label: 'Liberar Desconto?',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_boleto: {
      model: 'selectSimple',
      name: 'allow_boleto',
      label: 'Liberar Boleto',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_cortesy: {
      model: 'selectSimple',
      name: 'allow_cortesy',
      label: 'Liberar Cortesia',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_credit_card: {
      model: 'selectSimple',
      name: 'allow_credit_card',
      label: 'Liberar Cartão de Credito',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    integration_api: { model: 'input', type: 'text', name: 'integration_api', label: 'URL de integração' },
  };



  const formSchemaUpdate = {
    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'Logo',
    },
    logo_adicional: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo_adicional',
      label: 'Logo Adicional',
    },
    background: {
      model: 'uploadImage',
      type: 'upload',
      name: 'background',
      label: 'Fundo',
    },

    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto de Seleção',
    },
    data_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'data_image',
      label: 'Imagem da Data',
    },
    header_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'header_image',
      label: 'Imagem de capa'
    },
    fixed_image_header: {
      model: 'uploadImage',
      type: 'upload',
      name: 'fixed_image_header',
      label: 'Imagem de fundo Fixa'
    },




    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const modules = [

    (lineData: Record<string, any>) =>
      FinanceReportByProjectModule({
        lineData,

      }),
    (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title,
        formSchema: formSchemaUpdate,
        validationSchema: {},
        endpoint,
      }),
    (lineData: Record<string, any>) =>
      DuplicateNewProjectModule({
        lineData,

      }),


  ];

  if (user?.email === 'guilherme@encontrodigital.com.br') {
    modules.push((lineData: Record<string, any>) =>
      lineModules.delete({
        lineData,
        title,
        formSchema,
        validationSchema: formValidation,
        endpoint,
      }));
  }



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      /* () =>
         lineModules.create({
           title,
           formSchema,
           validationSchema: formValidation,
           endpoint,
         }), */
      () => CreateNewProjectModule({})
    ],
    bodyModules: modules
  };
  const { addToast } = useToast();
  const getFullReport = () => {
    addToast({ title: 'Gerando Relatório', type: 'success' });
    downloadit({ url: '/check-full-finance-report' })
  }
  return (
    <Container>
      <button style={{ alignSelf: 'center', margin: '10px auto' }} onClick={() => getFullReport()} className='defaultButtonMini'>Relatório de saques </button>

      <SearchComponent renderFunction={template} {...config} />
    </Container>
  );
};

export default EventsAdmin;
