import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';


const rotate = keyframes`
from {
  transform: rotate(0deg) ;
}
to{
  transform: rotate(360deg) ;
}
`

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}


@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;


}

body {
background: rgb(44, 62, 78);
background-size: cover ;
background-attachment: fixed;


>iframe {
  position: relative !important;
}

.fieldsList{
  span, h2, h3, p {
    color: #333 !important;

  }

  input{
    &::placeholder {
      color: #777;
    }
  }
}

.row{
  display: flex ;
}

.row-start{
  display: flex ;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.greenButton2{
    padding: 10px 20px;
  //  background: linear-gradient(180deg, rgba(255,189,101,1) 0%, rgba(254,211,146,1) 100%);
  background: #4e5768 !important;
  color: #fff;
    border: 0px;
    border-radius: 10px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;

    &:hover{
      transform: scale(1.15) ;
    }
  }

  .IconBall, .IconBallActive{

background: #ddd ;
color: #333;
padding: 10px;
font-size: 16px;
border-radius: 50%;
cursor: pointer ;

}

.IconBallActive{
background: #2c3e4e ;
color: #fff;
}


.greenButton2Mini{
    padding: 10px;
    background: #fff;
    color: #2c3e4e;
    border: 0px;
    border-radius: 10px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;
    font-size: 14px;

    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;

    &:hover{
      transform: scale(1.15) ;
    }
  }


  .greenButton2Mini{
    padding: 10px;
    background: #fff;
    color: #c3a257;
    border: 0px;
    border-radius: 10px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;
    font-size: 14px;

    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;

    &:hover{
      transform: scale(1.15) ;
    }
  }

  .greenButton2Mini.active{
    
    background: #c3a257 !important;
    color: #fff;
     
  }

  .row{
    display: flex ;
    flex-direction: row;
    align-items:center;
  }

  .row-start{
    display: flex ;
    flex-direction: row;
    justify-content: flex-start;
  }

  .gap-sm{
    gap: 15px;
  }

  .gap-xs{
    gap: 7.5px;
  }

  .outerBlock{
 
 padding:  15px;
 border: 1px solid #ddd;
 border-radius: 7.5px;


 .inputDiv{
  display: flex;
 align-items: center;
 justify-content: flex-start;
 border-radius: 5px;
 padding: 10px 15px;
 border: 1px solid #ddd;
background: #fff ;
color: #333;
 transition: 0.5s all;
 width: 100%;
 min-height: auto;
 flex-direction: column;

 input, select{
   padding: 5px 5px;
   &::placeholder {
      color: #777;
    }
 }

 >span{
   position: relative ;
   top: auto;
   left: auto;
   width: 100%;
   text-align: left;
 }

 }
 
  }

  .defaultBlock{
    display: flex;
 align-items: center;
 justify-content: flex-start;
 border-radius: 5px;
 padding: 10px 15px !important;
 border: 1px solid #ddd;
background: #fff ;
color: #333;
 transition: 0.5s all;
 width: 100%;
  }

  .borderBlock{
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex ;
    gap: 15px;
    border: 1px solid #333;
    padding: 5px 16px;
    border-radius: 5px;
  }


.iconButtonRef {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 0;
      padding: 5px;
      border:1px solid #ddd;
      border-radius: 50%;
      cursor: pointer;
      >svg{
        font-size: 12px;
      }

      background: #fff;
      color: #333;
    }

.row-to-column{
  width: 100%;
display: flex ;
justify-content: center;
align-items: center;
flex-direction: row;

@media (max-width: 750px) {
  flex-direction: column;
  }

} 
.row-to-column-start{
  width: 100%;
display: flex ;
justify-content: center;
align-items: center;
flex-direction: row;

@media (max-width: 750px) {
  flex-direction: column;
  justify-content: flex-start;
  }
} 
.gap-sm{
gap: 15px;
} 
.pad{
  padding: 15px;
}

.tableRef{
  width: 100%;
  max-width: 800px;
  background: #fff ;
    border: 2px solid #ccc;
    margin: 10px auto;
    border-collapse: collapse;

    table{
      border-collapse: collapse;  
    }

    tr.highlight{
      background: rgb(12, 148, 144);
      color: #fff;
      th{
        color: #fff !important;
        text-align: left; 
      }
    }
    td,th {
      border: 1px solid #ddd;
      padding: 10px;
      color: #333;
    }
  }

.slick-track, .slick-slider, .slick-list, #sliderList{
  width: 100%;
}

.slick-slider, .slick-list, #sliderList{
  margin: 0px;
  padding: 0px;

  img{
    width: 100%;
  }


}

.slick-dots{
  bottom: 10px;

  button{
    height: 30px;
    width: 30px;
    &:before{
      height: 30px;
    width: 30px;
    font-size: 10px;
    }
    
  }
}

 
  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;



  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.padding{
  padding: 15px;
}

.margin{
  margin: 15px;
}

.shadowBox{
  display: flex;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgb(7 13 28 / 25%) 0px 10px 100px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#root{
  min-height: 100vh;
 


}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'MontserratBold',Helvetica,  sans-serif;

}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Montserrat',Helvetica,  sans-serif;
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}


.computerOnly{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

.fixedBottom{
    z-index: 0;
    position: fixed;
    bottom: -30px;
    right: 0px;
    width: 45%;
}

div.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);

      svg{
        cursor: pointer;
        transition: 0.4s all ;
        &:hover{
          transform: scale(1.1) ;
        }
      }
   
    }

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 5px 5px rgba(171, 230, 255,0.7);
        background: rgb(171, 230, 255) !important;
        border-color: #fff;
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      flex-direction: column;
      display: flex ;
      justify-content: center;
      align-items: center;
 
 
      font-weight: bold;

      background: rgb(51, 51, 51) !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;
    
      &:hover {
        transform: translateY(-10px);
 
      }
    }

    .tip{
      padding: 5px;
     font-size: 14px;
      width:100%;
      text-align: left;
      font-weight: bold;
   
      color: #916f00 !important;
      display: flex ;
      justify-content: flex-start;
      align-items: center;

      >svg {
        animation: ${rotate} 5s infinite ;
      }
     
  
    }

    .rotateAnimation{
      animation: ${rotate} 5s infinite ;
    }

    .simpleButton {
         margin-top: 0px;
 
  
      padding: 5px;
      text-align: center;
      background: #fff !important;
      color: #333  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #333  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini,     button.defaultButtonMini2, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: rgb(0,15,64) !important;
      color: ${defaultTheme.defaultColor}  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }


    button.defaultButtonMini2{
      padding: 10px 20px  !important ;
      margin-top: 25px ;
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      background: #555;
      color: #fff;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
 

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}
`;
